<template>
  <div class="main">
    <div class="forgetPsd">
      <div class="pageNav">
        <font>{{ $fanyi("基本信息") }}</font>
        <font class="nowPageName">
          / {{ changeType == "password" ? $fanyi("登录密码修改") : ""
          }}{{ changeType == "email" ? $fanyi("电子邮箱修改") : ""
          }}{{ changeType == "mobile" ? $fanyi("手机号修改") : "" }}</font>
      </div>
      <div class="checkMethods" v-if="step == 2">
        <div class="checkMethodsCon">
          <div class="checkMethodsConTitle">{{ $fanyi("请选择认证方式") }}</div>
          <div class="rowAndCenter">
            <div class="checkMethodsOpt" @click="
              approveType = 'password';
            next();
            ">
              <img :src="passwordImageUrl" alt="" />
              <span>{{ $fanyi("密码验证") }}</span>
              <button>{{ $fanyi("选择") }}</button>
            </div>
            <!-- 手机号验证 -->
            <div class="checkMethodsOpt" @click="
              approveType = 'mobile';
            next();
            ">
              <img :src="mobileImageUrl" alt="" />
              <span>{{ $fanyi("手机号码验证") }}</span>
              <button>{{ $fanyi("选择") }}</button>
            </div>
            <div class="checkMethodsOpt" style="margin-right: 0; margin-top: -20px" @click="
              approveType = 'email';
            next();
            ">
              <img :src="emailImageUrl" alt="" />
              <span>{{ $fanyi("电子邮箱验证") }}</span>
              <button>{{ $fanyi("选择") }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="changePasswordBox" v-if="step >= 3">
        <nav class="step">
          <div class="stepOpt" :class="{ old: step > 3, active: step == 3 }">
            <label>{{ step > 3 ? "√" : "1" }}</label>
            <span>{{ $fanyi("身份验证") }}</span>
          </div>
          <hr />
          <div class="stepOpt" :class="{ old: step > 4, active: step == 4 }">
            <label>{{ step > 4 ? "√" : "2" }}</label>
            <span>{{ changeType == "password" ? $fanyi("登录密码修改") : ""
              }}{{ changeType == "email" ? $fanyi("电子邮箱修改") : ""
              }}{{ changeType == "mobile" ? $fanyi("手机号修改") : "" }}</span>
          </div>
          <hr />
          <div class="stepOpt" :class="{ old: step > 5, active: step == 5 }">
            <label>{{ step > 5 ? "√" : "3" }}</label>
            <span class="gengGaiChenGong">{{ $fanyi("更改成功") }}</span>
          </div>
        </nav>
        <div class="identityVerification passWordReg" v-if="step == 3 && approveType == 'password'">
          <p class="userInfo">
            <span></span>
            <button @click="qiTaRenZheng()">
              {{ $fanyi("其他认证方式") }}
            </button>
          </p>
          <div class="regInputBox password">
            <div class="regCodeBox">
              <p class="inputName">{{ $fanyi("请输入登录密码") }}：</p>
              <el-input class="newShuRuKuang" type="password" v-model="oldPassword" show-password
                :placeholder="$fanyi('请输入旧登录密码')" />
            </div>
          </div>

          <button class="nextBtn" @click="passwordTypeNext()">
            {{ $fanyi("下一步") }}
          </button>
        </div>
        <div class="identityVerification" v-if="step == 3 && approveType != 'password'">
          <p class="userInfo">
            <span>{{
              approveType == "mobile" ? $fanyi("手机号码") : $fanyi("邮箱")
            }}：{{ login_info[approveType]
              }}{{
                approveType == "mobile"
                  ? $store.state.userInfo.mobile
                  : $store.state.userInfo.email
              }}</span>
            <button @click="qiTaRenZheng()">
              {{ $fanyi("其他认证方式") }}
            </button>
          </p>

          <div class="regInputBox">
            <div class="regCodeBox">
              <p class="inputName">{{ $fanyi("验证码") }}：</p>
              <input class="shuRuKuang regCodeInput" type="text" v-model="regCode" :placeholder="$fanyi('请输入验证码')"
                style="border: 1px solid #e2e2e2; padding-left: 15px" />
              <span class="tiJiaoYanZhengMa" v-if="showBtn" @click.prevent="sendVerificationCode">
                {{ $fanyi("验证码获取") }}
              </span>
              <span class="tiJiaoYanZhengMa sixSec" v-else @click.prevent>
                {{ Countdown }} S
              </span>
            </div>
          </div>
          <button class="nextBtn" @click="next()">
            {{ $fanyi("下一步") }}
          </button>
        </div>
        <div class="newPassWord newFormYangShi" v-if="step == 4 && changeType == 'password'">
          <el-form ref="formRef" :rules="formRules" :model="newPassword">
            <el-form-item :label="$fanyi('新密码') + '：'" prop="one">
              <el-input v-model="newPassword.one" show-password @input="passwordChannelInputLimit"
                :placeholder="$fanyi('请输入新密码')"></el-input>
            </el-form-item>
            <el-form-item :label="$fanyi('再次确认密码') + '：'" prop="two">
              <el-input v-model="newPassword.two" show-password @input="repasswordChannelInputLimit"
                :placeholder="$fanyi('请再次输入密码')"></el-input>
            </el-form-item>
          </el-form>
          <button class="nextBtn" @click="next()">
            {{ $fanyi("下一步") }}
          </button>
        </div>
        <div class="newemail" v-if="step == 4 && changeType != 'password'">
          <div class="regInputBox" v-if="changeType == 'mobile'">
            <!-- <div class="regCodeBox">
              <p class="inputName">{{ $fanyi("国家") }}：</p>
              <el-select class="shuRuXiaLa" v-model="country" placeholder="">
                <el-option :label="'+34'" value="+34"></el-option>
              </el-select>
            </div> -->
          </div>
          <div class="regInputBox">
            <div class="regCodeBox">
              <p class="inputName">
                {{
                  (changeType == "mobile"
                    ? $fanyi("新手机号码")
                    : $fanyi("新电子邮箱")) + "："
                }}
              </p>
              <div class="mobile">
                <span class="Telephoneprefix" v-if="changeType == 'mobile'"><el-select v-model="country">
                    <el-option v-for="item in Areacodelist" :key="item.sx" :label="item.value" :value="item.value">
                      {{ $store.state.languagetype == "English" ? item.sx + ' ' + item.value : item.key + ' ' +
                        item.value }}
                    </el-option>
                  </el-select></span><input class="shuRuKuang mobile" :placeholder="changeType == 'mobile'
                    ? $fanyi('请输入新手机号')
                    : $fanyi('请输入新的电子邮箱')
                    " type=" text" v-model="newEmail.email" />
              </div>
            </div>
          </div>
          <div class="regInputBox">
            <div class="regCodeBox">
              <p class="inputName">{{ $fanyi("验证码") }}：</p>
              <input class="shuRuKuang regCodeInput" type="text" :placeholder="$fanyi('请输入验证码')"
                v-model="newEmail.regCode" />
              <span class="tiJiaoYanZhengMa" v-if="showBtn" @click.prevent="sendEmailCode">
                {{ $fanyi("验证码获取") }}
              </span>
              <span class="tiJiaoYanZhengMa sixSec" v-else @click.prevent>
                {{ Countdown }} S
              </span>
            </div>
          </div>
          <button class="nextBtn" @click="changeEmailNext()">
            {{ $fanyi("下一步") }}
          </button>
        </div>
        <div class="ChangeSuccess" v-if="step == 5">
          <div class="ChangeSuccessOpt">
            <p class="messageBox">
              <img src="../../../../assets/icon/adopt.png" alt="" />
              <span v-if="$route.query.type == 'password'">{{
                $fanyi("登录密码更改成功")
              }}</span>
              <span v-else-if="$route.query.type == 'email'">{{
                $fanyi("邮箱更改成功")
              }}</span>
              <span v-else-if="$route.query.type == 'mobile'">{{
                $fanyi("手机号更改成功")
              }}</span>
            </p>
            <!--            @click="$fun.toPage('/user/index')"-->
            <button class="nextBtn" @click="goLogin">
              {{ $fanyi("返回主页") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../../../../store";
import Areacode from '../../../../utlis/mobilephoneareacode.js'
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$fanyi("请再次输入密码")));
      } else if (value !== this.newPassword.one) {
        callback(new Error(this.$fanyi("密码输入不一致")));
      } else {
        callback();
      }
    };
    return {
      step: 2,
      changeType: this.$route.query.type,
      login_name: this.$store.state.userInfo.login_name, //用户名
      emailImageUrl: require("../../../../assets/user-img/new-menu/certification3.svg"),
      mobileImageUrl: require("../../../../assets/user-img/new-menu/certification2.svg"),
      passwordImageUrl: require("../../../../assets/user-img/new-menu/certification1.svg"),
      login_info: "abner1", //用户账户信息
      approveType: "password", //用户验证身份的方式
      showBtn: true, //发送验证码按钮控制
      Countdown: 60, //秒数
      regCode: "", //验证码
      regNext: false,
      oldPassword: "",
      country: "+001", //手机号区号
      onumber: "", //绑定的邮箱或手机号
      tiShi: "",
      Areacodelist: Areacode,// 区号列表
      numberTishiTwo: "",
      numberTishi: "",
      newPassword: {
        one: "",
        two: "",
      },
      newEmail: {
        email: "",
        regCode: "",
      },
      formRules: {
        one: [
          {
            required: true,
            pattern: /^.{6,16}$/,
            message: this.$fanyi("请输入6-请输入6-16位包含大小写字母和数字的密码"),
          },
        ],
        two: [
          {
            required: true,
            validator: validatePass2,
          },
        ],
        checktwo: [],
        mobileone: [],
        emailone: [],
      },
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.step = 2;
        this.changeType = this.$route.query.type;
        this.$forceUpdate();
      },
    },
  },
  components: {},
  computed: {},
  created() { },
  methods: {
    passwordTypeNext() {
      this.$api
        .userCheckPassword({
          password: this.oldPassword,
        })
        .then((res) => {

          if (res.data != true) {
            this.regNext = false;
            return this.$message.error(this.$fanyi("密码错误"));
          }
          this.step++;
        });
    },

    // 更改用户信息并进入下一步
    changeEmailNext() {
      if (!this.newEmail.email || !this.newEmail.regCode) {
        return this.$message(this.$fanyi("缺少必填选项"));
      }
      // var emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      // if (
      //   this.changeType == "email" &&
      //   !emailRegExp.test(this.newEmail.email)
      // ) {
      //   return this.$message(this.$fanyi("请输入正确的邮箱地址"));
      // }
      this.checkVerificationCode(() => {
        if (this.changeType == "mobile") {
          this.$api
            .userMobileSave({
              mobile: this.newEmail.email,
              area_code: this.country
            })
            .then((res) => {

              if (res.code != 0)
                return this.$message.error(this.$fanyi(res.msg));
              this.step++;
            });
        }
        if (this.changeType == "email") {
          this.$api
            .userEmailSave({
              email: this.newEmail.email,
            })
            .then((res) => {

              if (res.code != 0)
                return this.$message.error(this.$fanyi(res.msg));
              this.step++;
            });
        }
      }, "changeEmail");
    },
    // 设置新密码
    changePassword() {
      let datas = {
        password: this.newPassword.one,
        login_name: this.login_name,
        verification: {
          number: this.onumber,
        },
      };
      this.$api.userPasswordSave(datas).then((res) => {
        if (res.success == true) {
          this.step++;
          store.commit("userData", null);
          localStorage.removeItem("user_token");
        } else {
          this.$message.error(this.$fanyi("操作失败"));
        }
      });
    },
    passwordChannelInputLimit(e) {
      // this.data.expressNumber = e.replace(/[^A-Za-z0-9]/g, '');
      this.newPassword.one = e.replace(/[^A-Za-z0-9~!@#$%^+-.]/g, "");
    },
    repasswordChannelInputLimit(e) {
      // this.data.expressNumber = e.replace(/[^A-Za-z0-9]/g, '');
      this.newPassword.two = e.replace(/[^A-Za-z0-9~!@#$%^+-.]/g, "");
    },
    //鼠标移入移出
    changeImageSrc(key, way) {
      // switch (key) {
      //   case 1:
      //     way === 'hover'
      //       ? (this.passwordImageUrl = require('../../../../assets/newImg/password_2.png'))
      //       : (this.passwordImageUrl = require('../../../../assets/user-img/changeImg/password1.svg'))
      //     break
      //   case 2:
      //     way === 'hover'
      //       ? (this.mobileImageUrl = require('../../../../assets/phone.svg'))
      //       : (this.mobileImageUrl = require('../../../../assets/newImg/mobile_1.svg'))
      //     break
      //   case 3:
      //     way === 'hover'
      //       ? (this.emailImageUrl = require('../../../../assets/newImg/email_2.svg'))
      //       : (this.emailImageUrl = require('../../../../assets/newImg/email_1.svg'))
      //     break
      // }
    },
    // 验证验证码
    checkVerificationCode(fn, type) {
      let datas = {
        occasion: "forget",
        number:
          this.approveType == "email" ? this.onumber : this.onumber.substr(3),
        code: this.regCode,
      };
      if (type == "changeEmail") {
        datas.occasion = "auto";
        datas.number = this.newEmail.email;
        datas.code = this.newEmail.regCode;
        if (this.changeType == "mobile") {
          datas.area_code = this.country;
        }
      }

      if (!datas.number) {
        return this.$message(this.$fanyi("重要参数为空"));
      }
      this.$api.checkVerificationCode(datas).then((res) => {
        if (res.success == true) {
          fn();
        } else {
          this.$message.warning(this.$fanyi("验证码错误"));
          return false;
        }
      });
    },
    // 发送邮箱验证码
    sendEmailCode() {
      if (!this.newEmail.email) {
        return this.$message(this.$fanyi("缺少必填选项"));
      }
      // var emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      // if (
      //   this.changeType == "email" &&
      //   !emailRegExp.test(this.newEmail.email)
      // ) {
      //   return this.$message(this.$fanyi("请输入正确的邮箱地址"));
      // }

      // let mobileReg = /^(\w){9}$/;
      // if (this.changeType == "mobile" && !mobileReg.test(this.newEmail.email)) {
      //   return this.$message(this.$fanyi("请输入正确的手机号"));
      // }
      let datas = {
        occasion: "auto",
        tool: this.changeType,
        number: this.newEmail.email,
        login_name: this.login_name,
      };
      if (this.changeType == "mobile") {
        datas.area_code = this.country;
      }
      this.showBtn = false;
      this.Countdown = 60;
      var timer = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          this.showBtn = true;
          this.Countdown = 60;
          clearInterval(timer);
        }
      }, 1000);
      this.$api.sendVerificationCode(datas).then((res) => {
        this.showBtn = true;
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.showBtn = false;
        this.numberTishi = "";
      });
    },
    qiTaRenZheng() {
      this.step = 2;
      this.numberTishiTwo = "";
      this.numberTishi = "";
      this.onumber = "";
      this.oldPassword = "";
      this.regCode = "";
      this.tiShi = "";
      this.$forceUpdate();
    }, // 发送验证码
    //去登录
    goLogin() {
      store.commit("userData", null);
      localStorage.removeItem("user_token");
      this.$router.replace("/login");
    },
    sendVerificationCode() {
      let datas = {
        occasion: "forget",
        tool: this.approveType,
        number:
          this.approveType == "email" ? this.onumber : this.onumber.substr(3),

        login_name: this.login_name,
      };
      if (this.approveType == "mobile") {
        datas.area_code = this.country;
      }
      this.showBtn = false;
      this.Countdown = 60;
      var timer = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          this.showBtn = true;
          this.Countdown = 60;
          clearInterval(timer);
        }
      }, 1000);
      this.$api.sendVerificationCode(datas).then((res) => {
        this.showBtn = true;
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.showBtn = false;
        this.numberTishi = "";
      });
    },
    next() {
      switch (this.step) {
        case 2:
          this.onumber =
            this.approveType == "mobile"
              ? this.$store.state.userInfo.mobile
              : this.$store.state.userInfo.email;

          this.step++;
          break;
        case 3:
          this.checkVerificationCode(() => {
            this.Countdown = 0;
            this.step++;
          });
          break;
        case 4:
          this.$refs.formRef.validate((valid) => {
            if (valid) {
              this.changePassword();
            }
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../css/mixin.scss";

.main {
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  overflow: hidden;
  width: 1060px;
}

.forgetPsd {
  background: #ffffff;
  border-radius: 6px;
  // border: 1px solid #e2e2e2;

  .mobile {
    display: flex;
  }

  .Telephoneprefix {
    width: 80px;
    height: 48px;
    display: flex;
    border-radius: 6px;
    text-align: center;

    border-radius: 6px;

    justify-content: center;
    align-items: center;
    border: 2px solid #212121;
    margin-right: 10px;

    /deep/ .el-select {
      width: 98px;
      height: 44px;


      .el-input__inner {
        height: 44px;

        border: none;

        &:focus {
          height: 44px;
        }

        // &:blur {}
      }
    }
  }

  .pageNav {
    padding: 20px 0 20px 30px;
    height: 60px;
    background: #f2f2f2;
    border-radius: 6px 6px 0px 0px;

    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #999999;

    .nowPageName {
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
    }
  }

  .checkMethods,
  .changePasswordBox {
    width: 100%;
    margin: 0 auto;
    min-height: 442px;
    padding-bottom: 80px;
    background: #ffffff;
  }

  .rowAndCenter {
    padding-left: 50px;

    display: flex;
    align-items: center;
  }

  // 确认认证方式
  .checkMethods {
    position: relative;
    padding: 30px;

    .checkMethodsCon {

      //width: 450px;
      //margin: 0 auto;
      //padding-top: 124px;
      .checkMethodsConTitle {
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 30px;
      }

      h1 {
        position: absolute;
        font-size: 18px;

        color: #000000;
        line-height: 24px;

        top: 20px;
        left: 20px;
      }

      .checkMethodsOpt {
        width: 240px;
        min-height: 268px;
        padding-top: 14px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        border: 1px solid #e2e2e2;
        margin-right: 80px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        display: flex;
        align-items: center;

        flex-direction: column;
        margin-bottom: 20px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          width: 240px;
          height: 113px;
        }

        span {
          margin-top: 26px;
          height: 44px;
          color: #222222;
          text-align: center;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #222222;
        }

        button {
          margin-top: 20px;
          width: 160px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #ff730b;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #ff730b;
          margin-bottom: 25px;
        }
      }


    }
  }

  // 修改密码
  .changePasswordBox {
    .step {
      margin: 40px auto 40px;
      width: 680px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-family: PingFang-SC-Regular, PingFang-SC;

      hr {
        flex: 0 0 112px;
        height: 1px;

        background: #e1e1e1;
        border: none;
        margin: 0 20px;
      }

      .stepOpt {
        &.old {
          label {
            color: #ffffff;
            background: #608bff;
            outline: solid 3px rgba($color: #608bff, $alpha: 0.3);
            width: 24px;
            height: 24px;
            line-height: 24px;
          }

          span {
            color: #222222;
          }
        }

        &.active {
          label {
            color: #ffffff;
            background: #ff730b;
            outline: solid 3px rgba($color: #ff730b, $alpha: 0.3);
            width: 24px;
            height: 24px;
            line-height: 24px;
          }

          span {
            color: #222222;
            // background-color: pink;
            max-width: 200px;
          }
        }

        display: flex;
        align-items: center;
        justify-content: center;

        label {
          text-align: center;
          line-height: 24px;
          width: 24px;
          height: 24px;
          background: #ffd5b5;
          color: #222222;
          font-size: 14px;
          border-radius: 50%;
        }

        span {
          font-size: 16px;
          color: #999999;
          line-height: 16px;
          width: 120px;
          text-align: center;

          // &.gengGaiChenGong {
          //   width: 120px;
          //   text-align: center;
          // }
        }
      }
    }

    >div {
      width: 715px;
      margin: 0 auto;
    }

    // 身份验证
    .identityVerification {

      // 密码验证
      &.passWordReg {
        .password {
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: pink;
        }
      }

      // **********************************************************************************************************

      .userInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        margin-left: 38px;

        span {
          font-size: 18px;
          color: #000000;
          line-height: 24px;
        }

        button {
          font-size: 16px;
          color: #608bff;
          line-height: 18px;
          background-color: transparent;
          border: none;
          margin-right: 35px;
          outline: none;
        }
      }
    }

    // 修改成功
    .ChangeSuccess {
      .ChangeSuccessOpt {
        .messageBox {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-top: 80px;
          margin-bottom: 40px;

          img {
            width: 40px;
            height: 40px;
            margin-bottom: 20px;
          }

          span {
            font-size: 14px;

            color: #000000;
            line-height: 19px;
          }
        }
      }
    }
  }
}

// 星号
.import {
  font-size: 14px;

  color: #ff0000;
  line-height: 20px;
}

//第三部开始的下一步按钮
.nextBtn {
  display: block;
  width: 160px;
  height: 48px;
  background: #ff730b;
  border-radius: 4px;
  margin: 40px auto 0;
  padding: 0 35px;
  height: 48px;
  background: #ff730b;
  font-size: 16px;
  color: #ffffff;
  line-height: 18px;
}

.newFormYangShi {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /deep/.el-input__inner {
    width: 480px;
    height: 46px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    margin-bottom: 10px;
    border-radius: 6px;
  }

  /deep/.el-form-item__error {
    top: 87px;
  }

  /deep/.el-input__suffix {
    height: 48px;
  }

  .el-form-item {
    margin-bottom: 5px;
  }
}

.regInputBox {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  .regCodeBox {
    position: relative;

    .inputName {
      height: 20px;
      font-size: 14px;

      font-family: MicrosoftYaHei;
      // color: #222222;
      // color: #222222;
      line-height: 20px;
      margin-bottom: 10px;
    }

    .shuRuXiaLa {
      /deep/.el-input__inner {
        width: 480px;
        height: 48px;
        border-radius: 6px;
        background: #ffffff;
        border: 1px solid #e2e2e2;
      }
    }

    .shuRuKuang {
      width: 480px;
      height: 48px;

      background: #ffffff;
      border-radius: 6px;
      // border: 1px solid #e2e2e2;
      // border: none;
      padding: 0 20px 0 20px;

      &.regCodeInput {
        padding-right: 230px;
      }
    }

    .mobile {
      width: 480px;
    }

    .newShuRuKuang {
      width: 480px;
      height: 48px;
      background: #ffffff;
      // border: 1px solid #e2e2e2;

      /deep/ .el-input__inner {
        width: 480px;
        height: 48px;
        line-height: 48px;
        background: #ffffff;
        border-radius: 6px;
        padding: 0;
        padding-left: 15px;
      }

      &.regCodeInput {
        padding-right: 230px;
      }
    }

    .tiJiaoYanZhengMa {
      position: absolute;
      cursor: pointer;
      top: 45px;
      right: 10px;
      font-size: 15px;
      color: #ff730b;
      line-height: 19px;

      &.sixSec {
        color: #999999;
      }
    }
  }
}
</style>
